$body-bg:               #ffffff;

$primary:               #081B3C;
$secondary:             #56D1BB;
$tertiary:              #FFBB00;
$red:                   #E83A43;

// Spacing units
$spacing-xs: 1.5rem;
$spacing-sm: 2rem;
$spacing-md: 3rem;
$spacing-lg: 4rem;
$spacing-xl: 5rem;

// Extends the spacings map with pre-defined spacings
$spacers: (
  xs: $spacing-xs,
  sm: $spacing-sm,
  md: $spacing-md,
  lg: $spacing-lg,
  xl: $spacing-xl
);


// Grids and containers
$containers: (
    wide: 1440px,
    extra-wide: 1680px,
);


$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    6: ($spacer * 4),
    7: ($spacer * 5),
    8: ($spacer * 5.5),
    9: ($spacer * 6),
    10: ($spacer * 6.5)
  ),
  $spacers
);

//Colors

// Extends the colors map
//$colors: ();

// Extends the theme-colors map
//$theme-colors: ();

// Type

$enable-responsive-font-sizes:  false;

$font-family-sans-serif:   'brother-1816', sans-serif;

$font-size-sm:            1.0625rem;
$font-size-base:          1.0625rem;
$font-size-lg:            1.0625rem;

$font-weight-normal:      400;
$font-weight-bolder:      700;

$font-weight-base:        $font-weight-normal;
$font-weight-bold:        $font-weight-bolder;

$line-height-base:        1.5;

$body-color:              $primary;

$headings-font-family:    'brother-1816-printed' !default;
$headings-font-weight:    $font-weight-bolder;
$headings-line-height:    1.125;
$headings-margin-bottom:  .5rem;

$lead-font-size:          $font-size-lg;
$lead-font-weight:        $font-weight-normal;

//DISPLAY 
$display-line-height:     1.125;

$display1-size:           2.39rem;
$display1-weight:         $font-weight-bolder;

$display2-size:           2.18rem;
$display2-weight:         $font-weight-bolder;

$display3-size:           1.97rem;
$display3-weight:         $font-weight-bolder;

$display4-size:           1.5rem;
$display4-weight:         $font-weight-bolder;


$link-decoration:         none;

// Buttons
$btn-font-weight:         $font-weight-bold;
$btn-padding-y:           .75rem;
$btn-padding-x:           1rem;
$btn-border-radius:       .75rem;

$alert-border-radius:           0;
$alert-border-width:            0;
