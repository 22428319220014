.wf-label {
  display: none;
}
.wf-field::placeholder {
  color: #D8D8D8;
  -webkit-font-smoothing: antialiased;
}
.wf-field {
  border: $secondary 1px solid;
  width: 90%;
  padding: 10px 15px;
  margin-bottom: 20px;
}
.wf-select .wf-field {
  padding: 10px 12px;
}
textarea.wf-field {
  min-height: 200px;
}

input[type="submit"] {
  display: inline-block;
  font-weight: 800;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.75rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  background-color: $secondary;
  border-color: $secondary;
  border-radius: 0;
  border: none;
  color: $primary;
  &:hover{
    background-color: #38c9af;
    border-color: #34c0a7;
  }
}