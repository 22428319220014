.masthead-swiper{

    .swiper-slide {
      position: relative;
      height: 320px;
      min-height: 320px;
      @include media-breakpoint-up(md) {
        height:  490px;
        min-height: 400px;
      }
    }
  
    .swiper-slide--bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      &:after{
        content: '';
        // background-color: rgb(0 0 0 / 10%);
        background: linear-gradient(0deg, rgb(0 0 0 / 10%), transparent, transparent);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  
    .swiper-slide .text-layer {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      z-index: 5;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: flex-end;
      text-align: center;
      width: 100%;
    }

    .slider-title{    
      font-family: 'brother-1816-printed';
      font-weight: 900;
      font-size: 3rem;
      line-height: 1;
      padding: 0;
      margin: 0;
      color: #fff;
      bottom: -5px;
      position: absolute;
      left: 0;
      right: 0;
      word-break: break-word;
      @include media-breakpoint-up(md) {
        font-size: 4rem;
        bottom: -6px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 5rem;
        bottom: -11px;
      }
      @include media-breakpoint-up(xl) {
        font-size: 6rem;
      }
    }

}