.bigger{
    max-width: 1440px;
    margin: 0 auto;
}
.big{
    max-width: 1440px;
    margin: 0 auto;
}


.grid-temp-3 {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 15px;
    row-gap: 15px;
    @include media-breakpoint-up(md) { grid-template-columns: 1fr 1fr;}
    @include media-breakpoint-up(lg) { grid-template-columns: 1fr 1fr 1fr; }
}


//Loading recranet 
.recranet-element .rn-sticky-top {
    top: 130px!important;
}
.recranet-element .rn-btn-default {
    font-weight: 900!important;
    border-radius: 0!important;
    font-family: "brother-1816-printed";
}