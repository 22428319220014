.img-recreatie {
  text-align: center;
  @include media-breakpoint-up(md) {
    text-align: unset;
  }
}
.image-text.d-flex {
  flex-direction: column;
  text-align: center;
  @include media-breakpoint-up(md) {
      flex-direction: row;
      text-align: left;
  }    
}

.img-recreatie{
  position: relative;
  &:after{
      content: '';
      background-image: url('/dist/img/img-filter.svg');
      height: 250px;
      width: 251px;
      top: 0; 
      left: 0; 
      right: 0; 
      bottom: 0; 
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin: 0 auto;
  }
  img{
      height: 250px;
      width: 250px;
      position: relative;
      object-fit: cover;
      object-position: center;
  }
  &.img-recreatie--small{
    &:after{
      content: ''; 
      height: 150px;
      width: 151px;     
    }
    img{
      height: 150px;
      width: 150px;
    }
  }
}