h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: $spacing-xs;
}

.display-1.text-shadow, 
.display-2.text-shadow, 
.display-3.text-shadow, 
.display-4.text-shadow, 
.display-5.text-shadow, 
.display-6.text-shadow{ 
  text-shadow: -2px -2px 0px #56d1bb;
  &.text-shadow--white{
    text-shadow: -2px -2px 0px #ffffff;   
  }
}

.line-height-big li a {
  padding: 0px 0px 18px 0px;
  display: block;
}

.text-red {
  color: $red;
}

.page-section{
  padding-bottom: 3rem;
}