.page-section--faq{
    .row {
        z-index: 3;
        position: relative;
    }
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after{
        content: "";
        height: 80%;
        width: 100%;
        top: -80px;
        bottom: 0;
        left: 0%;
        right: 0;
        background-image: url('/dist/img/faq/questionmark.svg');
        background-repeat: no-repeat;
        z-index: 9999;
        position: absolute;
        background-position: 150% 50%;
        background-size: contain;
        z-index: 0;
        max-height: 500px;
        @include media-breakpoint-up(md) { 
            height: auto;
            top: 0;
            background-position: 85% 50%;
        }
    }
    a {
        color: $primary;
    }
    .accordion {
        width: 100%;
    }
    
    .accordion .card  {
        color: $primary;
        background: transparent;
        border: none;
        border-bottom: 1px solid $white;
        border-radius: 0;
    }
    
    .card-header {
        background: none;
        border: none;
    
        a{
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            z-index: 2;
            position: relative;
        }
    
    }

    .card-body{
        padding-right: 80px;
    }
    
    .card-header__title{
        font-weight: 900;
    }
    
    .material-icons{
        transition: 300ms;
    }
    
    .card-header a:not(.collapsed) .material-icons{
        transform: rotate(180deg);
    }
    
    
}

