//menu
.c-offcanvas--right { 
  height: 100%;
  width: 100%;
  transform: translate3d(100%, 0, 0);
  @include media-breakpoint-up(sm) { 
    width: 21em;
  }
  &:after{
    content: '';
    background-image: url('/dist/img/menu/sun-mobile.svg');
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom left;
    z-index: 0;
  }
}
.c-offcanvas--right {
  display: block;
  text-align: center;
  padding-top: 4rem;
  background-color: $tertiary;
  color: $white;
}
a#triggerButton{
  text-decoration: none;
}
.navbar-nav {
  text-align: right;
  position: relative;
  z-index: 2;
  .nav-item--active{
    .nav-link{
      background-color: $white;
    }
  }
  .nav-link {
    padding: 0;
    a {
      outline: none;
      display: block;
      padding: 15px 2.5rem 15px 0px;
      font-size: 1.2rem;
      font-weight: 700;
      font-family: 'brother-1816-printed';
      text-decoration: none;
      &:hover{
        background: $white;
      } 
      &.social{
        .material-icons{
          // transform: scale(1.5);
        }
        &:hover{
          background-color: transparent;
        }
      }
    }
    .empty {
      opacity: 0;
      height: 10px;
      padding: 0;
      pointer-events: none;
    }
  }
}
.c-button__text{
  .material-icons{
    color: white;
    font-size: 63px;
    position: absolute;
    left: 16px; 
    top: 16px;
    z-index: 9;
  }
}
  

.dropdown-menu.show {
  background: transparent;
  border: 0;
  text-align: right;
  width: 100%;
  right: 0;  
  padding: 0px 0px;
  .nav-link a{
    padding:5px 2.5rem 5px 0px;
    font-size: 90%;
    font-weight: 100;
    font-family: 'brother-1816';
  }
}








/* hamburger icon */
.index{
  #nav-icon2 span {
    background: $primary; 
  }
}
.scroll-offset {
  #nav-icon2 span {
    background: $primary; 
  }
}

.has-offcanvas--visible {
  #nav-icon2 span {
    background: $primary; 
  }
}

.c-offcanvas-content-wrap {
  transform: scale(.7); 
}

#nav-icon2 {
    width: 60px;
    height: 45px;
    position: relative; 
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  
  #nav-icon2 span {
    display: block;
    position: absolute;
    height: 5px;
    width: 50%;
    background: $primary;
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  .btn-menu{
    text-decoration: none;
  }
  
  #nav-icon2 span:nth-child(even) {
    left: 50%;
  }
  
  #nav-icon2 span:nth-child(odd) {
    left:0px;
  }
  
  #nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
    top: 0px;
  }
  
  #nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
    top: 18px;
  }
  
  #nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
    top: 36px;
  }
  
  // .c-button.is-active #nav-icon2 span:nth-child(1),.c-button.is-active #nav-icon2 span:nth-child(6) {
  //   -webkit-transform: rotate(45deg);
  //   -moz-transform: rotate(45deg);
  //   -o-transform: rotate(45deg);
  //   transform: rotate(45deg);
  // }
  
  // .c-button.is-active #nav-icon2 span:nth-child(2),.c-button.is-active #nav-icon2 span:nth-child(5) {
  //   -webkit-transform: rotate(-45deg);
  //   -moz-transform: rotate(-45deg);
  //   -o-transform: rotate(-45deg);
  //   transform: rotate(-45deg);
  // }
  
  // .c-button.is-active #nav-icon2 span:nth-child(1) {
  //   left: 5px;
  //   top: 7px;
  // }
  
  // .c-button.is-active #nav-icon2 span:nth-child(2) {
  //   left: calc(50% - 5px);
  //   top: 7px;
  // }
  
  // .c-button.is-active #nav-icon2 span:nth-child(3) {
  //   left: -50%;
  //   opacity: 0;
  // }
  
  // .c-button.is-active #nav-icon2 span:nth-child(4) {
  //   left: 100%;
  //   opacity: 0;
  // }
  
  // .c-button.is-active #nav-icon2 span:nth-child(5) {
  //   left: 5px;
  //   top: 29px;
  // }
  
  // .c-button.is-active #nav-icon2 span:nth-child(6) {
  //   left: calc(50% - 5px);
  //   top: 29px;
  // }
  