.page-header {
    transition: 200ms;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    z-index: 1018;
      
    .navbar{
      padding: 0;
    }
    &.no-image{
      background-color: #f6f0cf;
    }
    
    .logo{
      width: 115px;
      @include media-breakpoint-up(sm) { 
        width: 130px;
      } 
      img{width: 100%;}
    }
    .btn{
      font-size: 12px;
      @media (min-width: 323px) {
        font-size: unset;
      }
    }
}
.home{
  .page-header {
    &.no-image{
      background-color:$white;
    }
}
}
.scroll-offset{

  .page-header{
      z-index: $zindex-sticky;
      background-color: $white;
  }

}
