.page-section--content-two-blocks{
    .btn-recreatie {
      float: right;
      width: 100%;
    }
  }

.border-recreatie {
    border-bottom: 1px solid $secondary;
    padding-bottom: 40px;
    margin-top: 20px;
    @include media-breakpoint-up(md) { 
        border-bottom: none;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
.border-recreatie-md {
    @include media-breakpoint-up(md) { 
        border-right: 1px solid $secondary;
    }
}

.page-section--extra-content{
    img{
        max-width: 100%;
    }
}