.site-footer{
    font-size: 15px;
    color: $white;
    position: relative;
    overflow: hidden;
    .footer-link{
        color: $white;
        display: flex;
    }
    .arrow{
        .footer-link:after {
            content: '';
            font-family: 'Material Icons';
            content: "keyboard_arrow_right";
            color: $secondary;
            font-weight: 900;
            text-decoration: none;
            transition: 200ms;
        }
        .footer-link{
            text-decoration: none;
            &:hover{
                &:after{
                    transform: translateX(4px);
                }
            }
        }
    }
    .footer-line {
        background:$secondary;
        width: 100%;
        margin: 15px;
    }

    &:after {
        content: '';
        background-image: url('/dist/img/footer/sun-footer.svg');
        right: -62px;
        bottom: -40px;
        height: 200px;
        width: 200px;
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        @include media-breakpoint-up(md) { 
            right: -90px;
            bottom: -50px;
            height: 300px;
            width: 300px;
        }
    }
}
