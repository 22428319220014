.page-section--slider{
   
    .big{
        background-color: $primary;
        position: relative;
        z-index: 2;
        overflow: hidden;
        @include media-breakpoint-up(md) {
        background-color: $secondary;
            &:after {
                content: '';
                background-image: url('/dist/img/slider-bg.svg');
                position: absolute;
                top: 0;
                left: 0px;
                width: -webkit-fill-available;
                width: -moz-available;
                height: 100%;
                background-position: bottom right;
                background-repeat: no-repeat;
                background-size: cover;
                z-index: -1;
                transform: translateX(-20%);
                overflow: visible;
            }
        }
    }
}

    .product-swiper {
        position: relative;
    }

    .swiper-slide__box{
        position: relative;
        word-break: break-word;
        .img-fluid{
            object-fit: cover;
            width: 100%;
        }
        
        .swiper-slide--content{
            padding: 20px 20px 40px 20px;
            position: relative;
        }
        
        &:hover{
            text-decoration: none;
            .btn-recreatie .material-icons:after {
                transform: filter;
                filter: brightness(0.9);
            }
        }

    }
    .swiper-slide__heading{
        font-weight: 300;
        -webkit-font-smoothing: antialiased;
    }
    .swiper-slide__paragraph {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 15px;
    }
    
    .swiper-slide--content .btn-recreatie .material-icons {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }

    .navigation-swiper {
        padding-top: 2rem;
        justify-content: center;
        display: flex;
        .material-icons{
            padding: 20px;
            transform: scale(1.5);
        }
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 45%;
            padding-top: unset;
            justify-content: space-between;
            width: 100%;
            cursor: pointer;
            .material-icons{
                padding: unset;
                transform: scale(1);
            }
            .swiper-button-prev .material-icons{
                position: absolute;
                left: -80px;
            }

            .swiper-button-next .material-icons{
                position: absolute;
                right: -80px;
            }
        }


        &.home{
            position: absolute;
            justify-content: flex-end;
            margin-top: -335px;            
            width: auto;
            right: 5px;
            @include media-breakpoint-up(md) {
                right: 105px;
                margin-top: -470px;
            }
            @include media-breakpoint-up(xl) {
                margin-top: -70px;            
            }
            
            
            .swiper-button-prev .material-icons {
                position: absolute;
                left: -60px;
                    @include media-breakpoint-up(md) {
                        left: -50px;
                    }

            }
            
            @include media-breakpoint-up(md) {
                .btn-recreatie .material-icons{
                    height: 55px;
                    font-size: 55px;
                    
                    &:after {
                        height: 55px;
                        width: 55px;
                    }
                }
            }
        }
    }
    
