.page-section--cta{ 
    border-top: 1px solid $secondary;
    position: relative;
    z-index: 2;
    input#fieldEmail {
        padding: 10px;
        background: #f6f0cf;
        border: 1px solid $secondary;
        width: 100%;
    }
    @include media-breakpoint-up(md) { 
        background: #F6F0CF;
        border-top: none;
        &:before {
            content: '';
            background-image: url('/dist/img/cta/cta-left.svg');
            position: absolute;
            top: 0;
            left: 0px;
            width: -webkit-fill-available;
            height: 100%;
            background-position: bottom left;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: -1;
        }

        &:after {
            content: '';
            background-image: url('/dist/img/cta/cta-right.svg');
            position: absolute;
            top: 0;
            right: 0px;
            width: -webkit-fill-available;
            height: 100%;
            background-position: bottom right;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: -1;
        }
    }
}