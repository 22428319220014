.masthead{
  position: relative;
  &:after{
    content: '';
    position: absolute;
    left: 15px; 
    right: 0;
    background-image: url('/dist/img/header/header-group.svg');
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
    height: 50px; 
    bottom: -3px;
    width: auto;
    z-index: 2;
    @include media-breakpoint-up(sm){
      height: 70px;
      left: 30px; 
    }
  }
  &:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;
    top: 0;
    background-image: url('/dist/img/header/header-mobile-white.svg');
    @include media-breakpoint-up(sm){
      background-image: url('/dist/img/header/header-round-white.svg');
    }
  }
  &.no-image{
    background: #f6f0cf;
    &:before{
      content: '';
      background-image: url('/dist/img/header/header-mobile-white.svg');
      @include media-breakpoint-up(lg){
        background-image: url('/dist/img/header/header-round-white.svg');
      }
    }
    .masthead-wrapper .text-layer {
      top: -90px;
    }
    .masthead-title{  
      color: $primary;
    }
  }
    .masthead-wrapper {
      position: relative;
      height: 300px;
      min-height: 300px;
      @include media-breakpoint-up(md){
        height:  310px;
        min-height: 310px;        
      }
    }
  
    .masthead--bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      &:after{
        content: '';
        background-color: rgb(0 0 0 / 20%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  
    .masthead-wrapper .text-layer {
      position: absolute;
      top: -30px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;
      img{
        position: relative;
        z-index: 1019;
      }
    }
    .masthead-subtitle{  
      font-weight: 800;
      margin-bottom: 0;
    }
    .masthead-title{    
      margin-top: 0;
      font-size: 1.5rem;
      line-height: 1;
      color: white;
      @include media-breakpoint-up(md) {
        font-size: 3rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 4rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 5rem;
      }
    }

}