p a {
    color: #56d1bb;
    font-weight: 600;
    text-decoration: underline;
}

.btn{
    font-weight: 900;
    border-radius: 0;
    font-family: 'brother-1816-printed';
    margin-bottom: 0.5rem;
} 
  
.btn-secondary, .btn-light{
    color: $primary;
}
.btn-outline-primary{
    border-color: $secondary;
}

.btn-tertiary{
    background-color: $tertiary;
    color: $white;
}
.btn-reservation{
    position: relative;
}
.btn-reservation:after {
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background-image: url('/dist/img/btn-reservation.png');
}
.btn-reservation-now{
    position: relative;
}
.btn-reservation-now:after {
    position: absolute;
    top: -1px;
    left: 1px;
    right: 0;
    bottom: 0;
    content: '';
    background-image: url('/dist/img/btn-reservation-now.png');
}
.btn-recreatie{
    position: static;
    // display: table-footer-group;
    &:hover{
        text-decoration: none;
        .material-icons{
                &:after {
                    transform: filter;
                    filter: brightness(0.9);
                }
            }
        }
    .material-icons{ 
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        font-size: 34px;
        display: inline-flex;
        z-index: 1;
        &:after{
            content: '';
            background-image: url('/dist/img/btn-yellow.svg');
            height: 30px;
            width: 32px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            z-index: -1;
            position: absolute;
        }   
    }
    &.btn-recreatie--white{
        .material-icons:after{
            background-image: url('/dist/img/btn-white.svg');
        }
    }
    &.btn-recreatie--green{
        .material-icons:after{
            background-image: url('/dist/img/btn-green.svg');
        }
    }
    &.social{
        height: 36px;
        font-size: 40px;
        .material-icons:after{
            height: 42px;
            width: 43px;
        }   
        .material-icons:before {
            content: '';
            height: 28px;
            width: 38px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }    
       
        .facebook:before{
            background-image: url('/dist/img/social/facebook.svg');
        }
       
        .instagram:before{
            background-image: url('/dist/img/social/instagram.svg');
        }

        
    }
}