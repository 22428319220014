.contact{
  .list-unstyled {
    font-size: 22px;
  }
  a {
    color: $primary;
  }
  .introduction{
    h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
      margin-top: 0px;
    }
  }
}
